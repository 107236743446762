import { action, makeAutoObservable, observable } from 'mobx';
import { isEmpty, isUndefined } from 'lodash';

interface IUser {
    id: number;
    username?: string;
    lastname?: string;
    firstname?: string;
}

class UserStore {
    constructor() {
        makeAutoObservable(this);
    }

    @observable currentUser: IUser = { id: 0 };

    @observable shownUserName = '';

    @action setUserData(data: IUser) {
        const { username, firstname, lastname, id } = data;
        this.currentUser = { id, username, firstname, lastname };
        this.setShownUserName(data);
    }

    @action setShownUserName(user: IUser) {
        this.shownUserName = this.generateShownUserName(user);
    }

    generateShownUserName(user: IUser) {
        if (isUndefined(user)) {
            return '';
        }
        if (!isEmpty(user.lastname) && !isEmpty(user.firstname) && !isEmpty(user.username)) {
            return `${user.lastname} ${user.firstname} ${user.username}`;
        }

        if (!isEmpty(user.lastname) && !isEmpty(user.firstname)) {
            return `${user.lastname} ${user.firstname}`;
        }

        return user.username || '';
    }
}

export default new UserStore();

import { AlertOptions } from 'react-notification-alert';

import { api as apiConfig } from 'lib/config';
import notifyStore from 'store/notification';
import { GetListParams, ListData } from 'types/list.type';
import { Item } from 'types/item.enum';
import { Dictionary, AdminDictionaryId } from 'types/dictionary';
import { DeleteItemParams } from './interfaces';
import { getAxiosInstanceWithErrorNotification, getAxiosInstanceWithResultAndErrorNotification } from './axios';

const baseUrl = apiConfig.getBaseUrl();

// Ключ === первая часть в Item до /..
const apiUrls = {
    admin: 'admin',
};

const adminUrl = `${baseUrl}/${apiUrls.admin}`;

export const baseAxiosInstance = getAxiosInstanceWithErrorNotification(baseUrl);
export const adminAxiosInstance = getAxiosInstanceWithErrorNotification(adminUrl);
export const adminAxiosInstanceWithResultMessage = getAxiosInstanceWithResultAndErrorNotification(adminUrl);

export function getServiceApiUrlByItem(itemName: Item | string) {
    const [service, item] = itemName.split('/');

    const apiUrl = apiUrls[service];

    if (service && item && apiUrl) {
        return apiUrl;
    }

    return apiUrls.admin;
}

export const generateRequestsByItemName = <
    RESPONSE extends Record<any, string | number> = Record<string, any>,
    FILTERS extends Record<string, any> = Record<string, any>,
    UPDATES extends Record<string, any> = Record<string, any>,
    CREATE extends Record<string, any> = Record<string, any>,
>(
    itemName: Item | string,
) => {
    const itemUrl = `${itemName.split('/').pop()!}`;

    return {
        getList: async (params: GetListParams<FILTERS>): Promise<ListData<RESPONSE>> => {
            const { filters, sizePerPage, page, sortField, sortOrder } = params;

            const { data } = await baseAxiosInstance.get<ListData<RESPONSE>>(itemUrl, {
                params: {
                    page,
                    size: sizePerPage,
                    filters,
                    ...(sortField && sortOrder && { sort: { sortField, sortOrder } }),
                },
            });

            return data;
        },
        getItem: async ({ id }: { id: number | string }, search?: string): Promise<RESPONSE> => {
            const url = search ? `${itemUrl}/${id}${search}` : `${itemUrl}/${id}`;
            const { data } = await baseAxiosInstance.get<RESPONSE>(url);

            return data;
        },
        deleteItem: async ({ id, ...data }: DeleteItemParams): Promise<void> => {
            await baseAxiosInstance.delete(`${itemUrl}/${id}`, { data });

            notifyStore.showNotify({
                place: 'br',
                message: `Запись #${id} удалена`,
                type: 'success',
            } as AlertOptions);
        },
        deleteItemsMass: async (data: DeleteItemParams): Promise<void> => {
            await baseAxiosInstance.delete(`${itemUrl}`, { data });

            notifyStore.showNotify({
                place: 'br',
                message: 'Записи удалены',
                type: 'success',
            } as AlertOptions);
        },
        updateItem: async ({ id }: { id: number | string }, updates: UPDATES): Promise<void> => {
            await baseAxiosInstance.patch(`${itemUrl}/${id}`, updates);

            notifyStore.showNotify({
                place: 'br',
                message: `Запись #${id} обновлена`,
                type: 'success',
            } as AlertOptions);
        },
        createItem: async (data: CREATE): Promise<void> => {
            await baseAxiosInstance.post(`${itemUrl}`, data);

            notifyStore.showNotify({
                place: 'br',
                message: 'Запись успешно создана',
                type: 'success',
            } as AlertOptions);
        },
        createItemMass: async (data: CREATE[]): Promise<void> => {
            await baseAxiosInstance.post(`${itemUrl}-mass`, { data });

            notifyStore.showNotify({
                place: 'br',
                message: 'Запись успешно создана',
                type: 'success',
            } as AlertOptions);
        },
    };
};

export async function getDictionary(params: { adminDictionaryId?: AdminDictionaryId }): Promise<Dictionary[]> {
    const response = await baseAxiosInstance.get('dictionary', { params });

    if (!response.data) {
        notifyStore.showNotify({
            place: 'br',
            message: 'Ошибка загрузки словаря',
            type: 'danger',
        } as AlertOptions);

        return [];
    }

    return response.data;
}

export const authApi = {
    loginByToken: (accessToken: string) => {
        authApi.setAuthorizationTokenHeader(accessToken);

        return baseAxiosInstance.get('auth/login-by-token');
    },

    login: ({ username, password }: { username: string; password: string }) =>
        baseAxiosInstance.post('auth/login', { username, password }),

    setAuthorizationTokenHeader: (accessToken: string) => {
        baseAxiosInstance.defaults.headers = Object.assign(baseAxiosInstance.defaults.headers, {
            Authorization: `Bearer ${accessToken}`,
        });
    },
};

export default { axiosInstance: adminAxiosInstance };

import React, { useState } from 'react';
import {
    Button,
    Card,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col,
} from 'reactstrap';
import { useStore } from 'store';

import { StyledI } from 'components/icons/styled';

const Login: React.FC = () => {
    const [username, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [isRemember, setIsRemember] = useState(false);
    const authStore = useStore('auth');
    const isSubmitDisabled = authStore.inProgress || username.length < 2 || password.length < 8;
    const handleSubmit = () =>
        authStore.login({
            username,
            password,
            isRemember,
        });

    return (
        <Container className="mt-9 pb-5">
            <Row className="justify-content-center">
                <Col lg="5" md="7">
                    <Card className="bg-secondary border-0 mb-0">
                        <CardBody className="px-lg-5 py-lg-5">
                            <div className="text-center text-muted mb-4">
                                <small>Введите авторизационные данные</small>
                            </div>
                            <Form role="form">
                                <FormGroup>
                                    <InputGroup className="input-group-merge input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <StyledI className="fas fa-user" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="Пользователь"
                                            type="text"
                                            onChange={(e) => setLogin(e.target.value)}
                                        />
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup>
                                    <InputGroup className="input-group-merge input-group-alternative">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <StyledI className="fas fa-key" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            placeholder="Пароль"
                                            type="password"
                                            onChange={(e) => setPassword(e.target.value)}
                                        />
                                    </InputGroup>
                                </FormGroup>
                                <div className="custom-control custom-control-alternative custom-checkbox">
                                    <input
                                        className="custom-control-input"
                                        id="customCheckLogin"
                                        type="checkbox"
                                        defaultChecked={isRemember}
                                        onClick={() => setIsRemember((value) => !value)}
                                    />
                                    <label className="custom-control-label" htmlFor="customCheckLogin">
                                        <span className="text-muted">Запомнить</span>
                                    </label>
                                </div>
                                <div className="text-center">
                                    <Button
                                        disabled={isSubmitDisabled}
                                        onClick={handleSubmit}
                                        className="my-4"
                                        color="primary"
                                        type="button"
                                    >
                                        Вход
                                    </Button>
                                </div>
                            </Form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default Login;

import styled from 'styled-components';

export const StyledI = styled.i<{ isLoading?: boolean; activeColor?: string; fontSize?: string }>`
    color: ${({ isLoading }) => (isLoading ? '#f7fafc' : '#5e72e4')};
    cursor: pointer;
    font-size: ${({ fontSize }) => fontSize || '1em'};
    :hover {
        color: ${({ activeColor }) => activeColor || '#2dce89'};
    }
`;

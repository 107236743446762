import { observable, action, reaction, makeAutoObservable } from 'mobx';
import { isNull, isUndefined } from 'lodash';

import { authApi } from 'api';
import adminStore from 'store/admin';

export type TToken = string | null;

export interface ICredentials {
    username: string;
    password: string;
    isRemember?: boolean;
}

class AuthStore {
    @observable accessToken: TToken = window.localStorage.getItem('access_token');

    @observable authHeader: { Authorization: string } | undefined;

    @observable isLoginTryDone = false;

    @observable isLoggedIn = false;

    @observable inProgress = false;

    @observable isRemember: boolean | undefined = false;

    @observable errors = undefined;

    constructor() {
        makeAutoObservable(this);
        reaction(
            () => this.accessToken,
            (accessToken) => {
                if (!isNull(accessToken) && this.isRemember === true) {
                    window.localStorage.setItem('access_token', accessToken);
                } else {
                    window.localStorage.removeItem('access_token');
                }
            },
        );
    }

    @action setToken(accessToken: TToken) {
        if (!isNull(accessToken)) {
            this.accessToken = accessToken;
            this.authHeader = { Authorization: `Bearer ${accessToken}` };
            authApi.setAuthorizationTokenHeader(accessToken);
        }
    }

    @action loginByToken() {
        if (this.accessToken) {
            this.authHeader = { Authorization: `Bearer ${this.accessToken}` };
            this.inProgress = true;

            authApi
                .loginByToken(this.accessToken)
                .then(
                    action((res) => {
                        adminStore.setUserData(res.data);
                        this.isLoggedIn = true;
                    }),
                )
                .catch(
                    action(() => {
                        this.isLoggedIn = false;
                    }),
                )
                .finally(
                    action(() => {
                        this.inProgress = false;
                        this.isLoginTryDone = true;
                    }),
                );

            return;
        }

        this.isLoggedIn = false;
        this.isLoginTryDone = true;
    }

    @action login({ username, password, isRemember }: ICredentials) {
        this.isRemember = isRemember;
        this.inProgress = true;
        this.errors = undefined;

        return authApi
            .login({ username, password })
            .then(
                action((res) => {
                    if (!isUndefined(res.data.info)) {
                        adminStore.setUserData(res.data.info);
                    }
                    this.isLoggedIn = true;

                    this.setToken(res.data.accessToken);
                }),
            )
            .finally(
                action(() => {
                    this.inProgress = false;
                    this.isLoginTryDone = true;
                }),
            );
    }

    @action logout() {
        this.accessToken = null;
        this.authHeader = undefined;
        this.isLoggedIn = false;
        this.isLoginTryDone = false;
    }
}

export default new AuthStore();

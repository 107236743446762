import React from 'react';
import notification from './notification';
import dictionary from './dictionary';
import list from './list';
import auth from './auth';
import admin from './admin';

export const stores = Object.freeze({
    notification,
    list,
    dictionary,
    auth,
    admin,
});

export const storesContext = React.createContext(stores);
export const StoresProvider = storesContext.Provider;

export const useStores = () => React.useContext(storesContext);
export const useStore = <T extends keyof typeof stores>(store: T): typeof stores[T] =>
    React.useContext(storesContext)[store];

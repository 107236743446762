import { makeAutoObservable, action, runInAction } from 'mobx';
import { AdminDictionaryId, Dictionary, DictionaryParams, DictionaryType } from 'types/dictionary';
import { getDictionary } from '../api';

interface IDictionaryStore {
    [DictionaryType.ADMIN]: Partial<{
        [key in AdminDictionaryId]: Dictionary[];
    }>;
}

class DictionaryStore {
    constructor() {
        makeAutoObservable(this);
    }

    items: IDictionaryStore = {
        [DictionaryType.ADMIN]: {},
    };

    isLoading = false;

    @action loadAdminDictionary(adminDictionaryId: AdminDictionaryId) {
        if (!this.items[DictionaryType.ADMIN][adminDictionaryId]) {
            getDictionary({ adminDictionaryId }).then((dictionaryItems) => {
                runInAction(() => {
                    this.items[DictionaryType.ADMIN][adminDictionaryId] = dictionaryItems;
                });
            });
        }
    }

    @action loadDictionary(params: DictionaryParams) {
        if (params[DictionaryType.ADMIN]) {
            this.loadAdminDictionary(params[DictionaryType.ADMIN]!);
        }
    }
}

export default new DictionaryStore();

import { makeAutoObservable, action } from 'mobx';
import { Item } from 'types/item.enum';
import { Filter } from 'components/filters/types';

interface ListParams {
    filtersComponentParams?: Filter[];
    queryFilters?: Record<string, any>;
    page?: number;
    sizePerPage?: number;
    sortField?: string;
    sortOrder?: 'asc' | 'desc';
    currentPageItemsCount?: number;
}

class ListStore {
    constructor() {
        makeAutoObservable(this);
    }

    lists: {
        [key: string]: ListParams;
    } = {};

    @action setListParams(listParams: ListParams, item: Item) {
        this.lists[item] = { ...this.lists[item], ...listParams };
    }
}

export default new ListStore();

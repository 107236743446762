import { AxiosError } from 'axios';

const prepareError = (err: AxiosError<{ message?: string; error?: Array<{ data: unknown }> }> | any): string => {
    const serverErrors = err.response?.data?.error;

    const errTitle = err.message ? err.message : 'Неизвестная ошибка';

    if (
        serverErrors &&
        serverErrors[0]?.data &&
        (serverErrors[0].data as unknown) instanceof Array &&
        serverErrors[0].data[0] &&
        serverErrors[0].data[0].message
    ) {
        return `${errTitle}: ${serverErrors[0].data[0].message}`;
    }

    if (serverErrors && serverErrors[0]?.data) {
        return `${errTitle}: ${serverErrors[0].data}`;
    }

    if (err.response?.statusText) {
        return `${err.message}: ${err.response.statusText}`;
    }

    return errTitle;
};

export default prepareError;

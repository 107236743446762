import React from 'react';

import { Button, Container, Row, Col } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { isUndefined } from 'lodash';

interface IItemListHeader {
    title: string;
    creating?: {
        title: string;
        path: string;
    };
}

const ItemListHeader: React.FC<IItemListHeader> = ({ title, creating }) => {
    const { push } = useHistory();

    return (
        <div className="header">
            <Container fluid={true}>
                <div className="header-body">
                    <Row className="align-items-center py-4">
                        <Col lg="6" xs="7">
                            <h6 className="h2 d-inline-block mb-0">{title}</h6>
                        </Col>
                        {!isUndefined(creating) && (
                            <Col className="text-right" lg="6" xs="5">
                                <Button className="btn-primary" color="" onClick={() => push(creating.path)} size="sm">
                                    {creating.title}
                                </Button>
                            </Col>
                        )}
                    </Row>
                </div>
            </Container>
        </div>
    );
};

export default ItemListHeader;

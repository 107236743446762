import React from 'react';

import { PageButtonRendererOptions } from 'react-bootstrap-table-next';
import { isNumber } from 'lodash';
import styled from 'styled-components';
import classnames from 'classnames';

import PageSizeSelect from 'components/tables/pagination/PageSizeSelect';
import Pagination from 'components/tables/pagination/Pagination';
import paginationFactory, { PaginationCtxOptions } from 'react-bootstrap-table2-paginator';

// eslint-disable-next-line @typescript-eslint/no-redeclare
interface Pagination {
    sizePerPage: number;
    currentPage: number;
    total: number;
}

interface IGetParams {
    (params: Pagination): PaginationCtxOptions;
}

const PointerSpan = styled.span`
    cursor: pointer;
`;

const getPaginationOptions: IGetParams = ({ currentPage, sizePerPage, total }) => {
    const getPageNumber = (key: string) => {
        if (key === 'first') {
            return 1;
        }
        if (key === 'prev') {
            return currentPage - 1;
        }
        if (key === 'next') {
            return currentPage + 1;
        }
        if (key === 'last') {
            return Math.ceil(total / sizePerPage);
        }

        return 1;
    };

    const CustomButton = ({ page, active, disabled, onPageChange }: PageButtonRendererOptions) => {
        const customPage = page as unknown as { key: string };

        const handleClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
            e.preventDefault();
            const targetPage = isNumber(page) ? page : getPageNumber(customPage.key);
            onPageChange(targetPage, targetPage);
        };

        const key = isNumber(page) ? page : customPage.key;

        return (
            <li key={key} className={classnames('page-item', { active }, { disabled })}>
                <PointerSpan aria-hidden="true" className="page-link" onClick={handleClick}>
                    {page}
                </PointerSpan>
            </li>
        );
    };

    return paginationFactory({
        sizePerPage,
        page: currentPage,
        totalSize: total,
        alwaysShowAllBtns: true,
        showTotal: true,
        withFirstAndLast: true,
        pageButtonRenderer: CustomButton,
        firstPageText: <span key="first" className="fas fa-angle-double-left" />,
        prePageText: <span key="prev" className="fas fa-angle-left" />,
        lastPageText: <span key="last" className="fas fa-angle-double-right" />,
        nextPageText: <span key="next" className="fas fa-angle-right" />,
        firstPageTitle: 'Первая страница',
        lastPageTitle: 'Последняя страница',
        nextPageTitle: 'Следующая страница',
        prePageTitle: 'Предыдущая страница',
        sizePerPageRenderer: ({ onSizePerPageChange }) => (
            <PageSizeSelect defaultValue={sizePerPage} onSizePerPageChange={onSizePerPageChange} />
        ),
        paginationTotalRenderer: (from, to, size) => <Pagination to={to} from={from} size={size} />,
    });
};

export default getPaginationOptions;

import React from 'react';
import { FilterInputType } from '../../filters/types';
import { DeleteItem } from '../../../api/interfaces';
import { DictionaryParams } from '../../../types/dictionary';

export type ListColumn = ListDataColumn | ListActionsColumn;

export interface ListDataColumnDefaultFields {
    fieldName: string;
    title: string;
    size?: number;
    isSortable?: boolean;
    showFilter?: boolean;
    filterType?: FilterInputType;
}

export interface ListDataColumnDefault extends ListDataColumnDefaultFields {
    type:
        | ListColumnType.TEXT
        | ListColumnType.TEXT_EDITABLE
        | ListColumnType.TEXT_WITH_TOOLTIP
        | ListColumnType.TEXT_WITH_TOOLTIP_EDITABLE
        | ListColumnType.BOOLEAN
        | ListColumnType.BOOLEAN_EDITABLE
        | ListColumnType.DATE
        | ListColumnType.DATE_TIME
        | ListColumnType.DATE_TIME_EDITABLE;
}

export interface ListDataColumnTextFromDictionary extends ListDataColumnDefaultFields {
    type: ListColumnType.TEXT_FROM_DICTIONARY | ListColumnType.TEXT_FROM_DICTIONARY_TOOLTIP;
    dictionary: DictionaryParams;
}

export interface ListDataSelectColumn extends ListDataColumnDefaultFields {
    type: ListColumnType.SELECT;
    selectOptions?: Array<{ selectValue: any; title: any }>;
    integerSelectOptions?: { minValue?: number; maxValue: number; nullable?: boolean };
}

export interface ListEnumColumn extends ListDataColumnDefaultFields {
    type: ListColumnType.TEXT_ENUM_FILTERED;
    filterValues: Array<number | string>;
}

interface ListActionsColumn {
    type: ListColumnType.ACTIONS;
    title: string;
    size?: number;
    isFullShowButton?: boolean;
    isEditButton?: boolean;
    isRemoveButton?: boolean;
    isRemoveButtonWithModal?: boolean;
    sendExtraFields?: string[];
    extraActionButtons?: (
        row: Record<string, any>,
        actions: {
            reloadList?: (params?: { isDeleted: boolean }) => void;
            deleteItem?: null | DeleteItem;
        },
    ) => React.ReactNode[];
}

export type ListDataColumn =
    | ListDataColumnDefault
    | ListDataSelectColumn
    | ListEnumColumn
    | ListDataColumnTextFromDictionary;

export enum ListColumnType {
    ACTIONS = 'ACTIONS',
    TEXT = 'TEXT',
    TEXT_EDITABLE = 'TEXT_EDITABLE',
    TEXT_WITH_TOOLTIP = 'TEXT_WITH_TOOLTIP',
    TEXT_WITH_TOOLTIP_EDITABLE = 'TEXT_WITH_TOOLTIP_EDITABLE',
    DATE = 'DATE',
    DATE_TIME = 'DATE_TIME',
    DATE_TIME_EDITABLE = 'DATE_TIME_EDITABLE',
    BOOLEAN = 'BOOLEAN',
    BOOLEAN_EDITABLE = 'BOOLEAN_EDITABLE',
    SELECT = 'SELECT',
    TEXT_ENUM_FILTERED = 'TEXT_ENUM_FILTERED',
    TEXT_FROM_DICTIONARY = 'TEXT_FROM_DICTIONARY',
    TEXT_FROM_DICTIONARY_TOOLTIP = 'TEXT_FROM_DICTIONARY_TOOLTIP',
}

export const isActionColumn = (column: ListColumn): column is ListActionsColumn =>
    column.type === ListColumnType.ACTIONS;
